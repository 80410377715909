/* eslint-disable max-len */
import React from 'react'
import styled from 'styled-components'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

const Header = styled.div`
  position: relative;
  width: 100vw;
  max-height: 400px;
  margin: 0 auto 128px auto;
  z-index: -1;

  img {
    filter: brightness(0.6) contrast(0.76);
    max-height: 400px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 124px;
  }
`

const TitleBox = styled.div`
  position: absolute;
  top: 5%;
  display: flex;
  flex-direction: column;
  height: 95%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 4.5em;
    word-break: break-word;

    @media only screen and (max-width: 768px) {
      font-size: 3.5rem;
    }

    @media only screen and (max-width: 500px) {
      font-size: 2rem;
    }
  }

  h1,
  p {
    color: white;
  }
`

const Wrapper = styled.div`
  margin: auto;
  max-width: 350px;
  text-align: center;

  h1 {
    margin: 0 auto 24px;
  }

  img {
    border-radius: 50%;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;

  :hover {
    color: white;
  }

  ::after {
    content: " | ";
    color: white;
  }
`

export default ({ pageContext }) => (
  <StaticQuery
    query={graphql`
      {
        logo: file(name: { eq: "BodyTunes" }) {
          childImageSharp {
            fluid {
              src
              aspectRatio
            }
          }
        }
      }
    `}
    render={props => (
      <div>
        <SEO
          title={pageContext.name || 'Author'}
          keywords={[
            'bodytunes',
            'ems',
            'ems-training',
            'ems-studio',
            'fitness',
            'blog',
            'author'
          ]}
        />
        <Header>
          <Img fluid={props.logo.childImageSharp.fluid} alt="Header Image" />
          <TitleBox>
            <h1>{pageContext.name.toUpperCase()}</h1>
            <p>
              <StyledLink to="/">Home</StyledLink>
              <StyledLink to="/authors">Authors</StyledLink>
              {pageContext.name}
            </p>
          </TitleBox>
        </Header>

        <Layout>
          <Wrapper>
            <h1>Hi, I'm {pageContext.name}</h1>
            <Img fluid={pageContext.portrait.fluid} alt={pageContext.name} />
          </Wrapper>
        </Layout>
      </div>
    )}
  />
)
